<template>
  <div
    class="qooBox-main flex flex-col overflow-hidden"
    :style="{ height: `${windowsHeight}px` }"
  >
    <MainHeader v-if="$route.meta.layout == 'default'" />
    <div
      class="grow flex flex-col"
      :class="{ 'router-view-content': $route.meta.layout == 'default' }"
      :style="{ height: `${windowsHeight - 72}px` }"
    >
      <router-view />
    </div>
  </div>
  <OverlayLoader v-show="isLoading" />
</template>

<script setup>
import OverlayLoader from "@/components/base/overlay/OverlayLoader.vue";
import MainHeader from "@/components/base/MainHeader";
import { computed, onMounted, watch } from "vue";
import { useStore } from "@/store/loading.js";
import { useWindowSize } from "@vueuse/core";
import { useWorksheets } from '@/store/worksheet.js';

const { height: windowsHeight } = useWindowSize();

const store = useStore();
const worksheet = useWorksheets();

const isLoading = computed(() => {
  return store.isLoading  || worksheet.isLoading;;
});

onMounted(() => {
  if (store.numberOfAjaxCAllPending == 0) {
    store.mutationer({ isLoading: false });
  }
});
</script>

<style>
/* .router-view-content {
  padding-top: 6rem;
}

@media screen and (max-width: 480px) {
  .router-view-content {
    padding-top: 6.5rem;
  }
} */

/* @media (min-width: 480px) and (max-width: 768px) {
  .router-view-content {
    padding-top: 6.6rem;
  }
} */
.g {
  color: #ef45234d;
}
</style>
